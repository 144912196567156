import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BoolChip from '../../components/UI/BoolChip';
import DateView from '../../components/DateView';
import { IAdvertiser } from '../../api/advertisers';

interface IProps {
  advertisers: IAdvertiser[];
  onRowClick: any;
}

export default function AdvertisersList({ advertisers, onRowClick }: IProps) {

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Название</TableCell>
                <TableCell>Добавлен</TableCell>
                <TableCell>Баланс</TableCell>
                <TableCell>Активен</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {advertisers.map((a) => (
                <TableRow
                  hover
                  key={a.id}
                  onClick={() => onRowClick(a.id)}
                >
                  <TableCell>
                    {a.id}
                    {a.extId ? <span style={{fontSize: 10}}><br/>#{a.extId}</span>  : ''}
                  </TableCell>
                  <TableCell>{a.name}</TableCell>
                  <TableCell>
                    <DateView value={a.createdAt} />
                  </TableCell>
                  <TableCell>{a.walletBalance}</TableCell>
                  <TableCell>
                    <BoolChip value={a.active} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};
