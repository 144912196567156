import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Outlet } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

// const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || '';

const LoginLayout = () => {
  const classes = useStyles();
  // const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

  useEffect(() => {
    if (Cookies.get('auth:bo')) {
      return window.location.assign('/app/admins')
    }
    // @ts-ignore
    // if (!window.grecaptcha) {
    //   const s = document.createElement('script');
    //   s.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaKey}`;
    //   s.onload = function () {
    //     setRecaptchaLoaded(true);
    //   }
    //   s.onerror = function () {
    //     setRecaptchaLoaded(true);
    //   }
    //   s.onabort = function () {
    //     setRecaptchaLoaded(true);
    //   }
    //   document.body.appendChild(s);
    // } else {
    //   setRecaptchaLoaded(true);
    // }
  }, [])

  return (
    <div className={classes.root}>
      <TopBar />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
