import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import KiosksList from './KiosksList';
import KiosksFilter, { defaultFilter } from './KiosksFilter';
import KioskDetails from './KioskDetails';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import { useKiosksList, IKiosk } from '../../api/kiosks';
import { useBoardsList } from '../../api/boards';
import useFilter from '../../hooks/useFilter';

const emptyKiosk: IKiosk = {
  name: '',
}

export default function KiosksPage() {
  const { id } = useParams()
  const classes = useGlobalStyles();
  const [setFilter, filter] = useFilter(defaultFilter);
  const [{ items }, loading, load] = useKiosksList();
  const [{ items: boards }, loadingBoards, loadBoards] = useBoardsList();
  const [kiosk, setKiosk] = useState<any>()
  const navigate = useNavigate();

  useEffect(() => {
    load(filter);
    loadBoards({});
  }, [filter])

  useEffect(() => {
    if (id) {
      if (id === 'new') {
        setKiosk(emptyKiosk);
      } else if (items.length) {
        setKiosk(items.find((i: any) => i.id === parseInt(id)));
      }
    } else {
      setKiosk(undefined);
    }
  }, [id, items])

  return (
    <Page
      className={classes.root}
      title="Киоски"
      action={<Button variant="contained" color="primary" onClick={() => navigate('/app/kiosks/new')}>Добавить киоск</Button>}
    >
      <KiosksFilter filter={filter} onApply={setFilter} />
      <Container maxWidth={false}>
        <Box mt={3}>
          <Loader loading={loading || loadingBoards}>
            <KiosksList kiosks={items} onRowClick={(id: number) => navigate(`/app/kiosks/${id}`)} />
          </Loader>
        </Box>
      </Container>
      {kiosk && (
        <KioskDetails
          kiosk={kiosk}
          boards={boards}
          onSave={() => load(filter)}
          onClose={() => navigate('/app/kiosks')}
        />
      )}
    </Page>
  );
};
