import Api, { useFetchApi, useUpdateApi, IBaseModel } from './index';
import { IGroup } from './groups';
import { AdDuration, formMediaData } from './ads';

export interface INewsItem extends IBaseModel {
  name: string;
  title: string;
  shortText?: string;
  mainText?: string;
  video?: string;
  image: string;
  feedImage: string;
  source?: string;
  groups: IGroup[];
  active: boolean;
  pinned: boolean;
  deletedAt?: Date;
}

export interface INewsListRequest {
  name?: string;
  title?: string;
  updatedAfter?: Date;
  updatedBefore?: Date;
  active?: boolean;
  groups?: number[];
}

export function useNewsList() {
  return useFetchApi(
    async (query: INewsListRequest) => Api.post('/news/list', query),
    { items: [] }
  )
}

export interface ISaveNewsRequest {
  id?: number;
  name: string;
  title: string;
  shortText?: string;
  mainText?: string;
  video?: string;
  image?: string;
  feedImage?: string
  source?: string;
  groups: any[];
  showDuration: AdDuration;
}

export function useNewsCreate(cb: any) {
  return useUpdateApi(
    async (data: ISaveNewsRequest) => Api.post('/news/create', data),
    cb
  )
}

export const uploadNewsMedia = async (filesToUpload: any, item: INewsItem) => {
  const formData = formMediaData(filesToUpload, item.id);

  return Api.put('/news/upload/media', formData);
};

export function useNewsUpdate(cb: any) {
  return useUpdateApi(
    async (data: ISaveNewsRequest) => Api.post('/news/update', data),
    cb
  )
}

export function useNewsDelete(cb: any) {
  return useUpdateApi(
    async (id: any) => Api.post('/news/delete', { id }),
    cb
  )
}
