import React from 'react';
import { Input } from '../../components/Form';
import { required } from '../../validators';

export function LegalEntityData () {
  return (
    <>
    <Input name="legalEntity.address"
                label="Адрес организации"
                type="text"
                validate={required}
              />
              <Input name="legalEntity.inn"
                label="ИНН"
                type="text"
                validate={required}
              />
              <Input name="legalEntity.okpo"
                label="ОКПО"
                type="text"
                validate={required}
              />
              <Input name="legalEntity.ogrn"
                label="ОГРН"
                type="text"
                validate={required}
              />
              <Input name="legalEntity.kpp"
                label="КПП"
                type="text"
                validate={required}
              />
              <Input name="legalEntity.account_number"
                label="Номер счёта"
                type="text"
              />
              <Input name="legalEntity.bank_bik"
                label="БИК банка"
                type="text"
              />
              </>
  )
}