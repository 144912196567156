import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import BoardsList from './BoardsList';
import BoardsFilter, { defaultFilter } from './BoardsFilter';
import BoardDetails from './BoardDetails';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import { useBoardsList } from '../../api/boards';
import { useKiosksList } from '../../api/kiosks';
import { useGroupsList } from '../../api/groups';
import useFilter from '../../hooks/useFilter';

const emptyBoard = {
  name: '',
  title: '',
  kioskId: undefined,
  version: 1,
  groups: []
}

export default function BoardsPage() {
  const { id } = useParams()
  const classes = useGlobalStyles();
  const [setFilter, filter] = useFilter(defaultFilter);
  const [{ items }, loading, load] = useBoardsList();
  const [{ items: kiosks }, loadingKiosks, loadKiosks] = useKiosksList();
  const [{ items: groups }, loadingGroups, loadGroups] = useGroupsList();
  const [board, setBoard] = useState<any>()
  const navigate = useNavigate();

  useEffect(() => {
    load(filter);
    loadKiosks({});
    loadGroups({});
  }, [filter])

  useEffect(() => {
    if (id) {
      if (id === 'new') {
        setBoard(emptyBoard);
      } else if (items.length) {
        setBoard(items.find((i: any) => i.id === parseInt(id)));
      }
    } else {
      setBoard(undefined);
    }
  }, [id, items])

  return (
    <Page
      className={classes.root}
      title="Панели"
      action={<Button variant="contained" color="primary" onClick={() => navigate('/app/boards/new')}>Добавить панель</Button>}
    >
      <BoardsFilter filter={filter} onApply={setFilter} groups={groups} />
      <Container maxWidth={false}>
        <Box mt={3}>
          <Loader loading={loading || loadingKiosks}>
            <BoardsList
              boards={items}
              groups={groups}
              onRowClick={(id: number) => navigate(`/app/boards/${id}`)}
            />
          </Loader>
        </Box>
      </Container>
      {board && (
        <BoardDetails
          board={board}
          onSave={() => load(filter)}
          onClose={() => navigate('/app/boards')}
          kiosks={kiosks}
          groups={groups}
        />
      )}
    </Page>
  );
};
