import Api, { useFetchApi, useUpdateApi } from './index';
import { IKiosk } from './kiosks';

export interface IBoard {
  id: any;
  name: string;
  title: string;
  kiosk: IKiosk;
  groups?: any[];
  active: boolean;
  lastPing: string;
  deviceInfo: any;
  networkInfo: any;
  createdAt: string;
  updatedAt: string;
}

export interface IBoardsListRequest {
  name?: string;
  updatedAfter?: Date;
  updatedBefore?: Date;
  active?: boolean;
  groups?: number[];
}

export function useBoardsList() {
  return useFetchApi(
    async (query: IBoardsListRequest) => Api.post('/board/list', query),
    { items: [] }
  )
}

export function useBoardCreate(cb: any) {
  return useUpdateApi(
    async (data: IBoard) => Api.post('/board/create', data),
    cb
  )
}

export function useBoardUpdate(cb: any) {
  return useUpdateApi(
    async (data: IBoard) => Api.post('/board/update', data),
    cb
  )
}
