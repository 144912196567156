import { AdFormat } from '../api/ads';
import moment from 'moment';

export function typeToSizeHelpText(format: AdFormat): string {
  switch (format) {
    case AdFormat.FULLSCREEN:
      return '9:16 [2160px * 3840px]';
    case AdFormat.HALFSCREEN:
      return '[1080px * 840px] или [2160px * 1680px]';
    case AdFormat.FEED_1X1:
      return '[350px * 290px] или [700px * 580px]';
    case AdFormat.FEED_1X2:
      return '[350px * 540px] или [700px * 1080px]';
    case AdFormat.FEED_2X2:
      return '[710px * 540px] или [1420px * 1080px]';
    case AdFormat.FEED_3X2:
      return '[1070px * 540px] или [2140px * 1080px]';
    default:
      return format;
  }
}

export const getDaysDuration = (dateFrom: any, dateTo: any) => {
  return Math.abs(moment(dateFrom).endOf('day')
      .diff(moment(dateTo).endOf('day'), 'days'),
  ) + 1;
};
