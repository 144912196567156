import React from 'react';
import dateFormat from '../../helpers/dates';

interface IDateViewProps {
  value: Date | number | string;
  withTime?: boolean;
}

export default function DateView({ value, withTime = true }: IDateViewProps) {
  return <>{dateFormat(value, withTime)}</>
}