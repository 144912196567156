import React, { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IGroup } from '../../api/groups';
import Popup from '../../components/Popup';
import { BoolSelect, Input, Select, WysiwygField } from '../../components/Form';
import { INewsItem, uploadNewsMedia, useNewsCreate, useNewsDelete, useNewsUpdate } from '../../api/news';
import { isURL, required } from '../../validators';
import { BackDrop } from '../../components/Utils/BackDrop';
import { CircularProgress } from '@mui/material';
import MediaDetails from './MediaDetails';
import { AdDuration } from '../../api/ads';

const initFilesToUpload = { video: null, image: null, feedImage: null };

let filesToUpload = { video: null, image: null, feedImage: null };

export type InputType = React.ChangeEvent<HTMLInputElement>;

const useStyles = makeStyles((theme) => ({
    root: {},
    media: {
        height: 150,
        marginBottom: theme.spacing(2),
    },
}));

type NewsMediaField = 'video' | 'image' | 'feedImage';

interface IProps {
    item: INewsItem;
    onSave: any;
    onClose: any;
    groups: IGroup[];
}

export default function NewsDetails({ item, groups, onSave, onClose }: IProps) {
    const useSave = item.id ? useNewsUpdate : useNewsCreate;
    const classes = useStyles();

    const [ uploading, setUploading ] = useState(false);

    const [ save, saving ] = useSave(async (res: any) => {
        if (res.success) {
            if (([ 'image', 'feedImage', 'video' ] as NewsMediaField[]).some((i) => filesToUpload[i])) {
                setUploading(true);

                await uploadNewsMedia(filesToUpload, { ...item, id: res.data.id });

                filesToUpload = initFilesToUpload;
                setUploading(false);
            }

            onSave();
            if (!item.id) {
                onClose();
            }
        }
    });

    const [ remove, removing ] = useNewsDelete((res: any) => {
        if (res.success) {
            onSave();
            onClose();
        }
    });

    const initial = {
        ...item,
        groups: item.groups?.map((g) => g.id),
    };

    const onChangeMediaInput = (type: NewsMediaField) => {
        return (file: any) => Object.assign(filesToUpload, {[type]: file});
    };

    return (
        <Popup onClose={onClose} title={item.name || 'Добавление новости'} width={600}>
            <Form onSubmit={(values) => save({
                ...values,
                groups: values.groups.map((id: number) => ({ id })),
                id: item.id,
                name: values.title,
            })} initialValues={initial} render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    {uploading && (
                        <BackDrop>
                            <CircularProgress/>
                            <br/>
                            Загрузка файлов...
                        </BackDrop>
                    )}

                    <Input name="title" label="Заголовок" validate={required} margin="normal"/>
                    <WysiwygField name="shortText" label="Анонс"/>
                    <WysiwygField name="mainText" label="Текст"/>

                    <Select name="showDuration"
                            label="Длительность показа"
                            options={Object.values(AdDuration).map((f) => ({ value: f, label: f }))}
                            fullWidth
                            validate={required}
                            margin="normal"/>

                    <MediaDetails onChangeMediaInput={onChangeMediaInput} values={values} classes={classes}/>

                    <Input name="source"
                           label="Источник (URL)"
                           margin="normal"
                           validate={isURL}
                           placeholder="https://site.com/news/1"
                           emptyAsNull/>
                    <Select name="groups"
                            label="Группы"
                            options={groups?.map((g) => ({ value: g.id, label: g.name }))}
                            fullWidth
                            margin="normal"
                            multi/>
                    <BoolSelect name="pinned" label="Закреплена" margin="normal" addEmpty={false} fullWidth={true}/>
                    <BoolSelect name="active" label="Активна" margin="normal" addEmpty={false} fullWidth={true}/>
                    <Grid container justify="space-between">
                        <Grid item>
                            <Box my={2}>
                                <Button type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={saving}>
                                    Сохранить
                                </Button>
                            </Box>
                        </Grid>
                        {item.id && (
                            <Grid item>
                                <Box my={2}>
                                    <Button variant="contained"
                                            size="large"
                                            className="deleteButton"
                                            disabled={removing}
                                            onClick={() => remove(item.id)}>
                                        Удалить
                                    </Button>
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </form>
            )}/>
        </Popup>
    );
}
