import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popup from '../../components/Popup';
import { BoolSelect, Input, Select } from '../../components/Form';
import { IKiosk, useKioskCreate, useKioskUpdate } from '../../api/kiosks';
import { IBoard } from '../../api/boards';
import { composeValidators, isIP, required } from '../../validators';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import plural from 'plural-ru';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  kiosk: IKiosk;
  boards: IBoard[];
  onSave: any;
  onClose: any;
}

export const useStyles = makeStyles((theme) => ({
  listImg: {
    'min-width': '24px'
  },
  listLabel: {
    'padding-left': '6px'
  }
}))

export default function KioskDetails({ kiosk, boards, onSave, onClose }: IProps) {
  const useSave = kiosk.id ? useKioskUpdate : useKioskCreate;
  const classes = useStyles();

  const [save, saving] = useSave((res: any) => {
    if (res.success) {
      onSave();
      if (!kiosk.id) {
        onClose();
      }
    }
  })

  const initial = {
    ...kiosk,
    boardIds: kiosk.boards?.map((b) => b.id)
  }

  return (
    <Popup onClose={onClose} title={kiosk?.name || 'Добавить киоск'}>
      <Form
        onSubmit={(values) => save({
          ...values,
          id: kiosk.id,
          boards: values.boardIds?.map((id: number) => boards.find((b) => b.id === id))
        })}
        initialValues={initial}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="name"
              label="Название"
              validate={required}
              margin="normal"
            />
            <Input
              name="address"
              label="Адрес"
              margin="normal"
            />
            <Input
              name="askGlonassId"
              label="ID ГЛОНАСС"
              margin="normal"
            />
            <Input
              name="controllerIp"
              label="IP-адрес контроллера"
              validate={isIP}
              margin="normal"
            />
            <Input
              name="description"
              label="Описание"
              margin="normal"
              multiline
            />
            <BoolSelect name="active" label="Активен" margin="normal" addEmpty={false} fullWidth={true} />
            {kiosk.id && (
              <Box my={2}>
                <Typography variant="h6">{plural(kiosk?.boards?.length || 0, '%d панель', '%d панели', '%d панелей')}</Typography>
                {(kiosk?.boards?.length || 0) > 0 && (<List dense={true}>
                  {kiosk?.boards?.map((b) =>
                    <ListItem>
                      <ListItemIcon className={classes.listImg}>
                        <SmartphoneIcon/>
                      </ListItemIcon>
                      <ListItemText className={classes.listLabel}
                        primary={b.name}
                      />
                    </ListItem>)}
                </List>)}
              </Box>)}
            <Box my={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={saving}
              >
                Сохранить
              </Button>
            </Box>
          </form>
        )}
      />
    </Popup>
  )
}
