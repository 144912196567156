import React from 'react';
import { IKiosksListRequest } from '../../api/kiosks';
import Filters from '../../components/Filter';
import { Input, BoolSelect, DateField } from '../../components/Form';

export const defaultFilter: IKiosksListRequest = {
  name: undefined,
  updatedAfter: undefined,
  updatedBefore: undefined,
  active: undefined
}

interface IProps {
  filter: IKiosksListRequest;
  onApply: any;
}

export default function KiosksFilter({ filter, onApply }: IProps) {
  return (
    <Filters initial={filter} onSubmit={onApply}>
      <DateField name="updatedAfter" label="Изменен после" />
      <DateField name="updatedBefore" label="Изменен до" />
      <Input name="name" label="Название" margin="dense" />
      <BoolSelect name="active" label="Активен" margin="dense" />
    </Filters>
  )
}
