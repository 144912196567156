import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Popup from '../../components/Popup';
import { Input, Select, BoolSelect } from '../../components/Form';
import { IAdmin, UserRoleName, useAdminCreate, useAdminUpdate } from '../../api/admins';
import { composeValidators, required, isEmail } from '../../validators';

interface IProps {
  admin: IAdmin;
  onSave: any;
  onClose: any;
}

export default function AdminDetails({ admin, onSave, onClose }: IProps) {
  const useSave = admin.id ? useAdminUpdate : useAdminCreate;

  const [save, saving] = useSave((res: any) => {
    if (res.success) {
      onSave();
      if (!admin.id) {
        onClose();
      }
    }
  })

  return (
    <Popup onClose={onClose} title={admin?.email || 'Новый пользователь'}>
      <Form
        onSubmit={(values) => save({ ...values, id: admin.id })}
        initialValues={admin}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="email"
              label="Email"
              validate={composeValidators(required, isEmail)}
              margin="normal"
            />
            {!admin.id && (
              <Input
                name="password"
                label="Пароль"
                validate={required}
                margin="normal"
              />
            )}
            <Select
              name="roles"
              label="Роли"
              // @ts-ignore
              options={Object.keys(UserRoleName).map((r) => ({ value: r, label: UserRoleName[r] }))}
              validate={required}
              fullWidth
              margin="normal"
              multi
            />
            {admin.id && <BoolSelect name="totp" label="Двухфакторная авторизация" margin="normal" fullWidth addEmpty={false} disabled={!admin.totp} />}
            <BoolSelect name="active" label="Активен" margin="normal" addEmpty={false} fullWidth={true} />
            <Box my={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                disabled={saving}
              >
                Сохранить
              </Button>
            </Box>
          </form>
        )}
      />
    </Popup>
  )
}
