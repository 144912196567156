import React from 'react';
import { IGroupsListRequest } from '../../api/groups';
import Filters from '../../components/Filter';
import { Input } from '../../components/Form';

export const defaultFilter: IGroupsListRequest = {
  name: undefined,
  internal: false
}

interface IProps {
  filter: IGroupsListRequest;
  onApply: any;
}

export default function GroupsFilter({ filter, onApply }: IProps) {
  return (
    <Filters initial={filter} onSubmit={onApply}>
      <Input name="name" label="Имя" margin="dense" />
    </Filters>
  )
}
