import React from 'react';
import { Field } from 'react-final-form';
import { Checkbox } from 'final-form-material-ui';
import FormControlLabel from '@material-ui/core/FormControlLabel';

interface ICBoxProps {
  label?: any;
  className: string;
}

const CBox = ({ label, className, ...rest }: ICBoxProps) => (
  <FormControlLabel
    className={className}
    control={<Checkbox {...rest} />}
    label={label}
  />
);

interface ICheckboxProps {
  label?: any;
  name: string;
  validate?: any;
  className?: any;
  disabled?: boolean;
}

const CheckboxField = (props: ICheckboxProps) => (
  <Field
    {...props}
    // @ts-ignore
    component={CBox}
    type="checkbox"
    validate={props.validate}
  />
);

export default CheckboxField;