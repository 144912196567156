import React from 'react';
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import AuthForm from './forms/Auth';
import { useGlobalStyles } from '../../theme/useGlobalStyles';

const LoginView = () => {
  const classes = useGlobalStyles();

  return (
    <Page
      className={classes.root}
      title="Login"
      hidePageTitle
    >
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="xs">
          <AuthForm />
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
