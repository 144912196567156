import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import CompareIcon from '@material-ui/icons/Compare';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DescriptionIcon from '@material-ui/icons/Description';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';

import MenuItem from './MenuItem';
import { useMyInfo } from '../../../api/selfSettings';
import { UserRole } from '../../../api/admins';


interface IMenuItem {
  href: string;
  icon: any;
  title: string;
  roles: UserRole[];
}

const items: IMenuItem[] = [
  {
    href: '/app/kiosks',
    title: 'Киоски',
    icon: CompareIcon,
    roles: [UserRole.ADMIN],
  },
  {
    href: '/app/boards',
    title: 'Панели',
    icon: SmartphoneIcon,
    roles: [UserRole.ADMIN],
  },
  {
    href: '/app/groups',
    title: 'Группы панелей',
    icon: GroupWorkIcon,
    roles: [UserRole.ADMIN],
  },
  {
    href: '/app/news',
    title: 'Новости',
    icon: DescriptionIcon,
    roles: [UserRole.ADMIN, UserRole.NEWS_MANAGER],
  },
  {
    href: '/app/ads',
    icon: MonetizationOnIcon,
    title: 'Реклама',
    roles: [UserRole.ADMIN, UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER, UserRole.LAWYER],
  },
  {
    href: '/app/advertisers',
    icon: RecentActorsIcon,
    title: 'Рекламодатели',
    roles: [UserRole.ADMIN, UserRole.AD_MANAGER],
  },
  {
    href: '/app/admins',
    icon: PeopleAltIcon,
    title: 'Администраторы',
    roles: [UserRole.ADMIN],
  },
  {
    href: '/app/revenue',
    icon: MonetizationOnIcon,
    title: 'Доход',
    roles: [UserRole.ADMIN],
  },
  {
    href: '/app/ad/analytics',
    icon: TimelineIcon,
    title: 'Рекламная аналитика',
    roles: [UserRole.ADMIN, UserRole.AD_MANAGER],
  },
  {
    href: '/app/ad/statistics',
    icon: BarChartIcon,
    title: 'Статистика показов',
    roles: [UserRole.ADMIN, UserRole.AD_MANAGER],
  },
  // {
  //   href: '/app/settings',
  //   icon: SettingsIcon,
  //   title: 'Settings'
  // },
  // {
  //   href: '/404',
  //   icon: AlertCircleIcon,
  //   title: 'Error'
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  }
}));

interface IProps {
  onMobileClose: any;
  openMobile: boolean;
}

export default function Menu({ onMobileClose, openMobile = false }: IProps) {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [{ data: { roles } }, loading, load] = useMyInfo();

  useEffect(() => {
    load();
  }, [])

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <Box p={2}>
        <List>
          {!loading && items.map((item) => (
            item.roles.filter((role) => {
              if (item.title === 'Рекламодатели' && !item.roles.includes(UserRole.ADMIN)) {
                item.title = 'Мои рекламодатели';
              }

              return (roles as string[]).includes(role.toString())
            }).length > 0 && (<MenuItem
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />)
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};
