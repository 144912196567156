import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface IStylesProps {
  width?: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: ({ width }: IStylesProps) => width || 500,
    height: '100vh',
    position: 'fixed',
    right: 0,
    top: 0,
    background: '#fff',
    overflowY: 'auto'
  },
  close: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5)
  }
}))

interface IProps {
  children: any | any[];
  onClose: any;
  title: any;
  width?: number;
}

export default function Popup({ children, width, onClose, title }: IProps) {
  const classes = useStyles({ width });

  return (
    <Dialog open onClose={onClose} scroll="body">
      <div className={classes.root}>
        <DialogTitle disableTypography={true}>
          <Typography variant="h4" color="primary">{title}</Typography>
          <IconButton className={classes.close} onClick={onClose} color="secondary" size="medium">
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
      </div>
    </Dialog>
  )
}
