import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { Input } from '../../../components/Form';
import { IAdvertiser } from '../../../api/advertisers';
import { isEmail } from '../../../validators';

interface IProps {
  advertiser: IAdvertiser;
  onSave: any;
  saving: boolean;
}

export default function AdvertiserContacts({ advertiser, onSave, saving }: IProps) {
  return (
    <Form
      onSubmit={onSave}
      mutators={{ ...arrayMutators }}
      initialValues={advertiser}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FieldArray name="contacts">
            {({ fields }) => (
              <>
                {fields.map((n, i) => (
                  <div key={i}>
                    <Input name={`${n}.name`} label="Имя" margin="normal" />
                    <Input name={`${n}.email`} label="Email" margin="normal" validate={isEmail} />
                    <Input name={`${n}.phone`} label="Телефон" margin="normal" />
                    <Divider style={{ margin: '16px 0' }} />
                  </div>
                ))}
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => fields.push({ name: '', email: '', phone: '' })}
                >
                  Добавить
                </Button>
              </>
            )}
          </FieldArray>
          <Box my={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={saving}
            >
              Сохранить
            </Button>
          </Box>
        </form>
      )}
    />
  )
}
