import Api, { useFetchApi, useUpdateApi } from './index';

export enum UserRole {
  ADMIN = 'ADMIN',
  NEWS_MANAGER = 'NEWS_MANAGER',
  AD_MANAGER = 'AD_MANAGER',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  LAWYER = 'LAWYER',
}
export type UserRoleType = keyof typeof UserRole

export const UserRoleName = {
  [UserRole.ADMIN]: 'Администратор',
  [UserRole.NEWS_MANAGER]: 'Менеджер новостей',
  [UserRole.AD_MANAGER]: 'Менеджер рекламы',
  [UserRole.CONTENT_MANAGER]: 'Контент менеджер',
  [UserRole.LAWYER]: 'Юрист',
}

export function useAuth(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/admin/login', data),
    cb
  )
}

export interface IAdminListRequest {
  email?: string;
  roles?: UserRole[];
  active?: boolean;
}

export interface IAdmin {
  id: string;
  email: string;
  roles: UserRole[];
  active: boolean;
  totp?: boolean;
}

export function useAdminsList() {
  return useFetchApi(
    async (query: IAdminListRequest) => Api.post('/admin/list', query),
    { items: [] }
  )
}

export interface IAdminCreateRequest {
  email: string;
  password: string;
  roles: UserRole[];
  secret?: string;
}

export function useAdminCreate(cb: any) {
  return useUpdateApi(
    async (data: IAdminCreateRequest) => Api.post('/admin/create', data),
    cb
  )
}

export function useAdminUpdate(cb: any) {
  return useUpdateApi(
    async (data: IAdminCreateRequest) => Api.post('/admin/update', data),
    cb
  )
}
