import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Field } from 'react-final-form';
import { KeyboardDatePicker } from '@material-ui/pickers';

interface IDateFieldProps {
  name: string;
  label?: string;
  max?: Date | number;
  min?: Date | number;
  validate?: any;
  margin?: boolean;
}

interface IStylesProps {
  margin?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: ({ margin }: IStylesProps) => ({
    marginTop: margin ? theme.spacing(2) : 'auto',
    '& .MuiInputAdornment-positionEnd': {
      marginRight: '-20px'
    }
  })
}));

const DateField = ({ name, label, min, max, validate, margin }: IDateFieldProps) => {
  const classes = useStyles({ margin });
  return (
    <Field
      name={name}
      // @ts-ignore
      render={({input: {onChange, value}}) => console.log('val', value) || (
        <KeyboardDatePicker
          // fix timezone issue with datepicker TODO update mui
          onChange={(val) => {
            if (val instanceof Date && val) {
              // move the time away from midnight, opposite direction of utc offset
              const offset = -val.getTimezoneOffset();
              val.setHours(Math.trunc(offset / 60), offset % 60);
              //using  trunc() because there could be negative offsets, too.
            }

            onChange(val);
          }}
          value={value || null}
          maxDate={max}
          minDate={min}
          format="dd.MM.yyyy"
          variant="inline"
          autoOk
          label={label}
          inputVariant="outlined"
          className={classes.root}
          margin="dense"
        />
      )}
      format={(v) => (v)}
      validate={validate}
    />
  );
}

export default DateField;
