import React from 'react';
import { Navigate } from 'react-router-dom';
import LoginLayout from './layouts/Login';
import LoginView from './views/Login';

import AppLayout from './layouts/App';
import KiosksPage from './views/Kiosks';
import BoardsPage from './views/Boards';
import AdminsPage from './views/Admins';
import GroupsPage from './views/Groups';
import NewsPage from './views/News';
import AdvertisersPage from './views/Advertisers';
import AdsPage from './views/Ads';
import AdminSettings from './views/AdminSettings';
import AdAnalyticsPage from './views/AdAnalytics';
import AdStatisticsPage from './views/AdStatistics';
import RevenuePage from './views/Revenue';

const routes = [
  {
    path: '/',
    element: <LoginLayout />,
    children: [
      { path: '/', element: <LoginView /> },
    ]
  },
  {
    path: 'app',
    element: <AppLayout />,
    children: [
      { path: 'kiosks', element: <KiosksPage /> },
      { path: 'kiosks/:id', element: <KiosksPage /> },
      { path: 'boards', element: <BoardsPage /> },
      { path: 'boards/:id', element: <BoardsPage /> },
      { path: 'groups', element: <GroupsPage /> },
      { path: 'groups/:id', element: <GroupsPage /> },
      { path: 'news', element: <NewsPage /> },
      { path: 'news/:id', element: <NewsPage /> },
      { path: 'ads', element: <AdsPage /> },
      { path: 'ads/:id', element: <AdsPage /> },
      { path: 'ad/analytics', element: <AdAnalyticsPage /> },
      { path: 'ad/statistics', element: <AdStatisticsPage /> },
      { path: 'advertisers', element: <AdvertisersPage /> },
      { path: 'advertisers/:id', element: <AdvertisersPage /> },
      { path: 'admins', element: <AdminsPage /> },
      { path: 'admins/:id', element: <AdminsPage /> },
      { path: 'account', element: <AdminSettings /> },
      { path: 'revenue', element: <RevenuePage /> },
      { path: '/', element: <Navigate to="/app/kiosks" /> }
    ]
  }
];

export default routes;
