import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { BoolSelect, Input, Checkbox } from '../../../components/Form';
import { IAdvertiser } from '../../../api/advertisers';
import { composeValidators, isEmail, required } from '../../../validators';
import { LegalEntityData } from '../../LegalEntity/LegalEntityData';

interface IProps {
  advertiser: IAdvertiser;
  onSave: any;
  saving: boolean;
}

export default function AdvertiserOverview({ advertiser, onSave, saving }: IProps) {
  return (
    <Form
      onSubmit={onSave}
      initialValues={advertiser}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Input
            name="name"
            label="Название"
            validate={required}
            margin="normal"
          />
          <Input
            name="email"
            label="Email"
            // validate={composeValidators(required, isEmail)}
            validate={composeValidators(required)} // TODO fix bd structure and validators
            margin="normal"
          />
          <Input
            name="description"
            label="Описание"
            margin="normal"
            multiline
          />
          <BoolSelect name="active" label="Активен" margin="normal" fullWidth addEmpty={false} />
          <Checkbox name="isLegalEntity" label="Юридическое лицо" />
          {values.isLegalEntity && (<LegalEntityData />)}
          <Box my={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={saving}
            >
              Сохранить
            </Button>
          </Box>
        </form>
      )}
    />
  )
}
