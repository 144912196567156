import Api, { useFetchApi, useUpdateApi } from './index';

export function useMyInfo() {
  return useFetchApi(
    async () => Api.post('/admin/self', {}),
    { data: {} },
    true
  )
}

export function useSetOtp(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/admin/self/totp', data),
    cb
  )
}

export function useChangePassword(cb: any) {
  return useUpdateApi(
    async (data: any) => Api.post('/admin/self/password', data),
    cb
  )
}
