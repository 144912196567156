import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Form } from 'react-final-form';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IGroup } from '../../../api/groups';
import { Input } from '../../../components/Form';
import { AdFormat, AdStatus, IAd } from '../../../api/ads';
import { composeValidators, isImageUrl, isURL, isVideoUrl, required } from '../../../validators';
import { IAdvertiser } from '../../../api/advertisers';
import { typeToSizeHelpText } from '../../../helpers/FormatMapper';
import { RbacRole } from '../../../components/Utils/Rbac';
import { UserRole } from '../../../api/admins';
import { FileUpload } from '../../../components/UploadFile';
import { CircularProgress } from '@mui/material';
import { BackDrop } from '../../../components/Utils/BackDrop';
import { Disabler } from './Overview';


export const useStyles = makeStyles((theme) => ({
    root: {},
    media: {
        height: 150,
        marginBottom: theme.spacing(2),
    },
}));

interface IProps {
    item: IAd;
    groups: IGroup[];
    advertisers: IAdvertiser[];
    save: any;
    saving: boolean;
    uploading: boolean;
    remove: any;
    removing: boolean;
    changeAdStatus: any;
    changingAdStatus: boolean;
    onChangeMediaInput: Function;
}


const MediaFiles = (props: IProps & { values: any }) => {
    const {
        item,
        values,
        onChangeMediaInput,
    } = props;

    const classes = useStyles();

    return (
        <>
            {item.id && (
                <Disabler editable={item.editable} name="video">
                    <FileUpload updateFilesCb={onChangeMediaInput('video')}
                                accept="video/*"
                                label="видео"
                                labelToUpload={`Видео (${typeToSizeHelpText(values.format)})`}
                                labelUploaded="Загруженное видео"
                                preview={values.video && (
                                    <video src={values.video}
                                           className={classes.media}
                                           autoPlay={false}
                                           playsInline={true}
                                           muted={true}
                                           style={{ margin: 0 }}
                                           disablePictureInPicture={true}
                                           controls={true}/>
                                )}>
                        <Disabler editable={item.editable} name="video">
                            <Input name="video"
                                   label="Укажите URL видео"
                                   margin="normal"
                                   validate={composeValidators(isURL, isVideoUrl)}
                                   placeholder={typeToSizeHelpText(values.format)}
                                   emptyAsNull/>
                        </Disabler>
                    </FileUpload>
                </Disabler>
            )}

            {item.id && (
                <Disabler editable={item.editable} name="image">
                    <FileUpload updateFilesCb={onChangeMediaInput('image')}
                                accept="image/*"
                                label="Изображение"
                                labelToUpload={`Изображение ${typeToSizeHelpText(values.format)}`}
                                labelUploaded="Загруженное изображение"
                                preview={values.image && (
                                    <img src={values.image} className={classes.media} style={{ margin: 0 }} alt=""/>
                                )}>
                        <Disabler editable={item.editable} name="image">
                            <Input name="image"
                                   label="Укажите URL изображения"
                                   margin="normal"
                                   validate={composeValidators(required, isURL, isImageUrl)}
                                   placeholder={typeToSizeHelpText(values.format)}/>
                        </Disabler>
                    </FileUpload>
                </Disabler>
            )}

            {item.id && (!values.format || (values.format !== AdFormat.FULLSCREEN.toString() && values.format !== AdFormat.HALFSCREEN.toString())) && (
                <>
                    <Disabler editable={item.editable} name="feedImage">
                        <FileUpload updateFilesCb={onChangeMediaInput('feedImage')}
                                    accept="image/*"
                                    label="Изображение для ленты"
                                    labelToUpload={`Изображение для ленты ${typeToSizeHelpText(values.format)}`}
                                    labelUploaded="Загруженное изображение для ленты"
                                    preview={values.feedImage && (
                                        <img src={values.feedImage}
                                             className={classes.media}
                                             style={{ margin: 0 }}
                                             alt=""/>
                                    )}>
                            <Disabler editable={item.editable} name="image">
                                <Input name="feedImage"
                                       label="Укажите URL изображения для ленты"
                                       margin="normal"
                                       validate={composeValidators(required, isURL, isImageUrl)}
                                       placeholder={typeToSizeHelpText(values.format)}/>
                            </Disabler>
                        </FileUpload>
                    </Disabler>
                </>
            )}
        </>
    );
};

export default function AdMedia(props: IProps) {
    const {
        item,
        save,
        saving,
        uploading,
    } = props;

    const initial = {
        ...item,
        groups: item.groups?.map((g) => g.id),
        advertiser: item.advertiser?.id,
    };

    const onSubmit = (values: any) => {
        save({
            ...values,
            groups: values.groups.map((id: number) => ({ id })),
            advertiser: { id: values.advertiser },
            id: item.id,
            status: 'DRAFT',
        });
    };


    return (
        <Form onSubmit={onSubmit} mutators={{
            setIsPublished: (args, state, utils) => {
                utils.changeValue(state, 'isPublished', () => true);
            },
        }} initialValues={initial} render={({ form, handleSubmit, values }) => (
            <form onSubmit={handleSubmit} style={{ position: 'relative' }}>

                {uploading && (
                    <BackDrop>
                        <CircularProgress/>
                        <br/>
                        Загрузка файлов...
                    </BackDrop>
                )}

                <MediaFiles {...props} values={values}/>

                {/** Ad actions  */}
                <Grid container justify="space-between" style={{ position: 'sticky', bottom: 0 }}>
                    <RbacRole roles={[ UserRole.ADMIN, UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER ]}
                              adStatus={item.status}
                              onStatuses={{
                                  [AdStatus.DRAFT]: [ UserRole.ADMIN, UserRole.AD_MANAGER ],
                                  [AdStatus.IN_WORK]: [ UserRole.ADMIN, UserRole.AD_MANAGER, UserRole.CONTENT_MANAGER ],
                                  [AdStatus.ON_CHECK]: [ UserRole.ADMIN ],
                                  [AdStatus.READY_TO_PUBLISH]: [ UserRole.ADMIN ],
                                  [AdStatus.ACTIVE]: [ UserRole.ADMIN ],
                                  [AdStatus.UNPUBLISHED]: [ UserRole.ADMIN ],
                              }}>
                        <Grid item>
                            <Box my={2}>
                                <Button type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        disabled={saving}>
                                    Сохранить
                                </Button>
                            </Box>
                        </Grid>
                    </RbacRole>
                </Grid>
                {/** /Ad actions  */}
            </form>
        )}/>
    );
}
