import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import AdvertisersList from './AdvertisersList';
import AdvertisersFilter, { defaultFilter } from './AdvertisersFilter';
import AdvertiserDetails from './AdvertiserDetails';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import { useAdvertisersList } from '../../api/advertisers';
import useFilter from '../../hooks/useFilter';
import { useAdminsList, UserRole } from '../../api/admins';
import { AuthStore } from '../../store/auth';
import { observer } from 'mobx-react-lite';


const emptyAdvertiser = {
  name: '',
  email: ''
}

function AdvertisersPage() {
  const { id } = useParams();
  const classes = useGlobalStyles();
  const [{ items }, loading, load] = useAdvertisersList();
  const [ { items: admins }, loadingAdmins, loadAdmins ] = useAdminsList();
  const [advertiser, setAdvertiser] = useState<any>();
  const [setFilter, filter] = useFilter(defaultFilter);
  const navigate = useNavigate();

  useEffect(() => {
    load(filter);
    AuthStore.fetchRoles().then(() => {
      AuthStore.roles.includes(UserRole.ADMIN) && loadAdmins();
    });
  }, [ filter ]);

  useEffect(() => {
    if (id) {
      if (id === 'new') {
        setAdvertiser(emptyAdvertiser);
      } else if (items.length) {
        setAdvertiser(items.find((i: any) => i.id === parseInt(id)));
      }
    } else {
      setAdvertiser(undefined);
    }
  }, [id, items])

  return (
    <Page
      className={classes.root}
      title="Рекламодатели"
      action={<Button variant="contained" color="primary" onClick={() => navigate('/app/advertisers/new')}>Новый рекламодатель</Button>}
    >
      <AdvertisersFilter filter={filter} onApply={setFilter} />
      <Container maxWidth={false}>
        <Box mt={3}>
          <Loader loading={loading}>
            <AdvertisersList advertisers={items} onRowClick={(id: any) => navigate(`/app/advertisers/${id}`)} />
          </Loader>
        </Box>
      </Container>
      {advertiser && !loadingAdmins && (
        <AdvertiserDetails
          admins={AuthStore.roles.includes(UserRole.ADMIN) ? admins : []}
          advertiser={advertiser}
          onSave={() => load(filter)}
          onClose={() => navigate('/app/advertisers')}
        />
      )}
    </Page>
  )
}


export default observer(AdvertisersPage);
