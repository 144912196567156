import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#fff',
    fontWeight: 700
  }
}))

export default function Logo() {
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.root}>CityTV • Панель управления</Typography>
  )
}
