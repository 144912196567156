import React, { useState } from 'react';
import { IGroup } from '../../api/groups';
import Popup from '../../components/Popup';
import TabsPanel from '../../components/Tabs';
import AdOverview from './details/Overview';
import AdSchedule from './details/Schedule';
import {
    AdStatusTranslate,
    IAd,
    uploadAdMedia,
    useAdChangeStatus,
    useAdCreate,
    useAdDelete,
    useAdUpdate,
} from '../../api/ads';

import { IAdvertiser } from '../../api/advertisers';
import AdMedia from './details/Media';


interface IProps {
    item: IAd;
    onSave: any;
    onClose: any;
    groups: IGroup[];
    advertisers: IAdvertiser[];
}

type AdMediaField = 'video' | 'image' | 'feedImage';


export default function AdDetails({ item, groups, advertisers, onSave, onClose }: IProps) {
    const useSave = item.id ? useAdUpdate : useAdCreate;

    const [ uploading, setUploading ] = useState(false);

    const [ save, saving ] = useSave(async (res: any) => {
        if (res.success) {

            if (([ 'image', 'feedImage', 'video' ] as AdMediaField[]).some((i) => filesToUpload[i])) {
                setUploading(true);

                await uploadAdMedia(filesToUpload, item);

                setFilesToUpload(initFilesToUpload);
                setUploading(false);
            }

            onSave();

            if (!item.id) {
                onClose();
            }
        }
    });

    const [ remove, removing ] = useAdDelete((res: any) => {
        if (res.success) {
            onSave();
            onClose();
        }
    });

    const [ changeAdStatus, changingAdStatus ] = useAdChangeStatus((res: any) => {
        if (res.success) {
            onSave();
            onClose();
        }
    });

    /**
     * Загрузка файлов после сохранения/создания
     * (вынесли на уровень, чтобы вызвать после cb())
     * */
    const initFilesToUpload = { video: null, image: null, feedImage: null };
    const [ filesToUpload, setFilesToUpload ] = useState(initFilesToUpload);

    const onChangeMediaInput = (type: AdMediaField) => {
        return (file: any) => setFilesToUpload({ ...filesToUpload, [type]: file });
    };

    const tabs = [
        {
            title: 'Информация',
            content: <AdOverview item={item}
                                 groups={groups}
                                 advertisers={advertisers}
                                 save={save}
                                 saving={saving}
                                 uploading={uploading}
                                 remove={remove}
                                 removing={removing}
                                 changeAdStatus={changeAdStatus}
                                 changingAdStatus={changingAdStatus}/>,
        },
        {
            title: 'Медиа файлы',
            content: <AdMedia item={item}
                              groups={groups}
                              advertisers={advertisers}
                              save={save}
                              saving={saving}
                              uploading={uploading}
                              remove={remove}
                              removing={removing}
                              changeAdStatus={changeAdStatus}
                              changingAdStatus={changingAdStatus}
                              onChangeMediaInput={onChangeMediaInput}/>,
            disabled: !item.id,
        },
        {
            title: 'Расписание и стоимость',
            content: <AdSchedule item={item} save={save} saving={saving}/>,
            disabled: !item.id,
        },
    ];

    const title = item.name
        ?
        <>
            <span style={{ color: 'grey', fontSize: 14 }}>
                ID: {item.id} {item.extId && (' • LK ID: ' + item.extId)} • {AdStatusTranslate[item.status]}
            </span>
            <br/>
            {item.name}
        </>
        :
        <>
            Создание новой рекламы
            <br/>
            <span style={{ color: 'grey', fontSize: 14 }}>
                Медиа файлы, расписание и стоимость доступно после создания
            </span>
        </>;

    return (
        <Popup onClose={onClose} width={600} title={title}>
            <TabsPanel tabs={tabs}/>
        </Popup>
    );
}
