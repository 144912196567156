import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popup from '../../components/Popup';
import { Input } from '../../components/Form';
import { IGroup, useGroupCreate, useGroupUpdate, useGroupDelete } from '../../api/groups';
import { required } from '../../validators';
import plural from 'plural-ru';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import Checkbox from '@material-ui/core/Checkbox';

interface IProps {
  group: IGroup;
  onSave: any;
  onClose: any;
}

export default function GroupDetails({ group, onSave, onClose }: IProps) {
  const useSave = group.id ? useGroupUpdate : useGroupCreate;

  const [recommended, setRecommended] = useState(group.isAdminRecommended)

  const [save, saving] = useSave((res: any) => {
    if (res.success) {
      onSave();
      if (!group.id) {
        onClose();
      }
    }
  })

  const [remove, removing] = useGroupDelete((res: any) => {
    if (res.success) {
      onSave();
      onClose();
    }
  })

  return (
    <Popup onClose={onClose} title={group.name || 'Новая группа'}>
      <Form
        onSubmit={save}
        initialValues={group}
        render={({ handleSubmit, values }) => (
          <form onSubmit={handleSubmit}>
            <Input
              name="name"
              label="Имя"
              validate={required}
              margin="normal"
            />
            <Input
              name="description"
              label="Описание"
              margin="normal"
              multiline
            />
            <div>
                <Checkbox name="isAdminRecommended" checked={recommended} onChange={(event, checked) => {
                    values.isAdminRecommended = checked
                    setRecommended(checked)
                }}/>

                <span>
                    Рекомендованная группа
                </span>
            </div>
            {group.id && (
              <Box my={2}>
                <Typography variant="h6">{plural(group.boards?.length || 0, '%d панель', '%d панели', '%d панелей')}</Typography>
                {group.boards?.length > 0 && (<List dense={true}>
                  {group.boards.map((b) =>
                    <ListItem>
                      <ListItemIcon>
                        <SmartphoneIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={b.name}
                        secondary={b.kiosk?.address}
                      />
                    </ListItem>)}
                </List>)}
              </Box>)}
            <Grid container justify="space-between">
              <Grid item>
                <Box my={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={saving}
                  >
                    Сохранить
                  </Button>
                </Box>
              </Grid>
              {group.id && (
                <Grid item>
                  <Box my={2}>
                    <Button
                      variant="contained"
                      size="large"
                      className="deleteButton"
                      disabled={removing}
                      onClick={() => remove(group.id)}
                    >
                      Удалить
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </form>
        )}
      />
    </Popup>
  )
}
