import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';


interface IProps {
    totalsPerson?: string,
    totalsFace?: string,
}


const useStyles = makeStyles({
    root: {
        width: 'calc(100% / 3 - 10px)',
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
});

export default function AdStatisticsCards(props: IProps) {
    const classes = useStyles();

    const { totalsPerson = 0, totalsFace = 0 } = props;

    return (
        <div style={{ display: 'flex', marginBottom: 20, gap: 16 }}>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Общее количество людей по периоду
                    </Typography>
                    <Typography variant="h2" component="h2">
                        {totalsPerson}
                    </Typography>
                </CardContent>
            </Card>
            <Card className={classes.root} variant="outlined">
                <CardContent>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        Общее количество просмотров по периоду
                    </Typography>
                    <Typography variant="h2" component="h2">
                        {totalsFace}
                    </Typography>
                </CardContent>
            </Card>
        </div>
    );
}