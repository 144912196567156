import React from 'react';
import { Input } from '../../components/Form';
import { FileUpload } from '../../components/UploadFile';


interface IProps {
    values: any;
    classes: any;
    onChangeMediaInput: any;
}

export default function MediaDetails({ values, onChangeMediaInput, classes }: IProps) {

    return (
        <>
            <FileUpload updateFilesCb={onChangeMediaInput('video')}
                        accept="video/*"
                        label="видео"
                        labelToUpload={`Видео 9:16 [2160px * 3840px]`}
                        labelUploaded="Загруженное видео"
                        preview={values.video && (
                            <video src={values.video}
                                   className={classes.media}
                                   autoPlay={false}
                                   playsInline={true}
                                   muted={true}
                                   disablePictureInPicture={true}
                                   controls={true}/>
                        )}>
                <Input name="video"
                       label="Видео (URL)"
                       margin="normal"
                       placeholder="9:16 [2160px * 3840px]"
                       emptyAsNull/>
            </FileUpload>

            <FileUpload updateFilesCb={onChangeMediaInput('image')}
                        accept="image/*"
                        label="изображение"
                        labelToUpload={`Изображение [1080px * 840px] или [2160px * 1680px]`}
                        labelUploaded="Загруженное изображение"
                        preview={values.image && (
                            <img src={values.image} className={classes.media} alt=""/>
                        )}>
                <Input name="image"
                       label="Изображение (URL)"
                       margin="normal"
                       placeholder="[1080px * 840px] или [2160px * 1680px]"/>
            </FileUpload>

            <FileUpload updateFilesCb={onChangeMediaInput('feedImage')}
                        accept="image/*"
                        label="изображение для ленты"
                        labelToUpload={`Изображение для ленты [350px * 290px] или [700px * 580px]`}
                        labelUploaded="Загруженное изображение для ленты"
                        preview={values.feedImage && (
                            <img src={values.feedImage} className={classes.media} alt=""/>
                        )}>
                <Input name="feedImage"
                       label="Изображение (URL) для ленты"
                       margin="normal"
                       placeholder="[350px * 290px] или [700px * 580px]"/>
            </FileUpload>
        </>
    );
}
