import React from 'react';
import Popup from '../../components/Popup';
import TabsPanel from '../../components/Tabs';
import AdvertiserOverview from './details/Overview';
import AdvertiserContacts from './details/Contacts';
import {
    IAdvertiser,
    useAdvertiserAdminsUpdate,
    useAdvertiserCreate,
    useAdvertiserTopUpBalance,
    useAdvertiserUpdate,
} from '../../api/advertisers';
import { RbacRole } from '../../components/Utils/Rbac';
import { IAdmin, UserRole } from '../../api/admins';
import { observer } from 'mobx-react-lite';
import { AuthStore } from '../../store/auth';
import AdvertiserAdmins from './details/Admins';
import AdvertiserTopUpBalance from './AdvertiserTopUpBalance';
import { Box } from '@material-ui/core';


interface IProps {
  admins: IAdmin[];
  advertiser: IAdvertiser;
  onSave: any;
  onClose: any;
}

function AdvertiserDetails({ advertiser, admins, onSave, onClose }: IProps) {
  const useSave = advertiser.id ? useAdvertiserUpdate : useAdvertiserCreate;

  const [save, saving] = useSave((res: any) => {
    if (res.success) {
      onSave();
      if (!advertiser.id) {
        onClose();
      }
    }
  });

  const [topUp, topUpInProgress] = useAdvertiserTopUpBalance(() => { window.location.reload() })

  const handleTopUp = (values: any) => topUp({...values, advertiser })

  const handleSaveAdvertiser = (values: IAdvertiser) => save({ ...values, legalEntity: values.isLegalEntity ? values.legalEntity : null })

  const [saveAdmins, savingAdmins] = useAdvertiserAdminsUpdate((res: any) => {
      if (res.success) {
          onSave();
          if (!advertiser.id) {
              onClose();
          }
      }
  });

  const tabs = [
    {
      title: 'Информация',
      content: (
      <>
        <AdvertiserOverview advertiser={advertiser} onSave={handleSaveAdvertiser} saving={saving} />
        {AuthStore.roles.includes(UserRole.ADMIN) && (
          <Box mt={5}>
            <AdvertiserTopUpBalance onSave={handleTopUp} saving={topUpInProgress} />
          </Box>
        )}
      </>
      )
    },
    advertiser.id
        ? {
          title: 'Контактные лица',
          content: <AdvertiserContacts advertiser={advertiser} onSave={handleSaveAdvertiser} saving={saving} />
        } : undefined,
    AuthStore.roles.includes(UserRole.ADMIN)
        ? {
          title: 'Пользователи',
          content: (
              <RbacRole roles={[ UserRole.ADMIN ]}>
                <AdvertiserAdmins advertiser={advertiser} onSave={saveAdmins} admins={admins} saving={savingAdmins}/>
              </RbacRole>
          ),
        } : undefined,
  ];

  return (
    <Popup onClose={onClose} title={advertiser.name ? <>
        <span style={{ color: 'grey', fontSize: 14 }}>
            ID: {advertiser.id}{advertiser.extId && (' • LK ID: ' + advertiser.extId)} • Баланс: {advertiser.walletBalance}
        </span>
        <br/></> : 'Новый рекламодатель'}>
      <TabsPanel tabs={tabs} />
    </Popup>
  )
}


export default observer(AdvertiserDetails);
