import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import plural from 'plural-ru';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { IGroup } from '../../api/groups';

interface IProps {
  groups: IGroup[];
  onRowClick: any;
}

interface IRowProps {
  g: IGroup;
  onRowClick: any;
}

function GroupRow({ g, onRowClick }: IRowProps) {
  const [expand, setExpand] = useState(false);

  return (
    <TableRow
      hover
      key={g.id}
      onClick={() => onRowClick(g.id)}
    >
      <TableCell>{g.name}</TableCell>
      <TableCell>
        {expand
          ? <>{g.description}</>
          : <Tooltip title={g.description} placement="top"><span>{g.description?.slice(0, 50)}...</span></Tooltip>
        }
      </TableCell>
      <TableCell>
        {expand && (
          <>
            {g.boards.map((b) => (
              <div>
                {b.name}
              </div>
            ))}
          </>
        )}
        {g.boards.length
          ? (
            <Link
              component="span"
              style={{ cursor: 'pointer' }}
              onClick={(e: any) => {
                e.stopPropagation();
                setExpand(!expand)
              }}
            >
              {expand
                ? <>Свернуть</>
                : <>{plural(g.boards.length, '%d панель', '%d панели', '%d панелей')}</>
              }
            </Link>
          ) : <>Нет панелей</>
        }
      </TableCell>
    </TableRow>
  )
}

export default function GroupsList({ groups, onRowClick }: IProps) {

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell>Описание</TableCell>
                <TableCell width="50%">Панели</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {groups.map((g) => <GroupRow g={g} onRowClick={onRowClick} />)}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};
