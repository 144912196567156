import React from 'react';
import { AdStatus, AdStatusTranslate, IAdsListRequest } from '../../api/ads';
import Filters from '../../components/Filter';
import { DateField, Input, Select } from '../../components/Form';
import { IGroup } from '../../api/groups';
import { IAdvertiser } from '../../api/advertisers';
import { RbacRole } from '../../components/Utils/Rbac';
import { UserRole } from '../../api/admins';


export const defaultFilter: IAdsListRequest = {
    name: undefined,
    updatedAfter: undefined,
    updatedBefore: undefined,
    active: undefined,
    status: undefined,
    groups: undefined,
    advertiser: undefined,
};

interface IProps {
    filter: IAdsListRequest;
    onApply: any;
    groups: IGroup[];
    advertisers: IAdvertiser[];
}

export default function AdsFilter({ filter, onApply, groups, advertisers }: IProps) {
    const options: { value: any, label: string }[] = Object.keys(AdStatus).map((status: any) => ({
        value: status,
        label: AdStatusTranslate[status as keyof typeof AdStatusTranslate],
    }));

    options.unshift({ value: null, label: 'Все статусы' });

    return (
        <Filters initial={filter} onSubmit={onApply}>
            <DateField name="updatedAfter" label="Изменена после"/>
            <DateField name="updatedBefore" label="Изменена до"/>
            <Input name="name" label="Заголовок" margin="dense"/>
            <Select name="groups"
                    label="Группы"
                    options={groups.map((g) => ({ value: g.id, label: g.name }))}
                    multi
                    margin="dense"/>
            <Select name="advertiser"
                    label="Рекламодатели"
                    options={advertisers.map((a) => ({ value: a.id, label: a.name }))}
                    multi
                    margin="dense"/>
            <RbacRole roles={[ UserRole.ADMIN, UserRole.AD_MANAGER ]}>
                <Select name="status" label="Статус" options={options} margin="dense" defaultValue={null}/>
            </RbacRole>
        </Filters>
    );
}
