import makeStyles from '@material-ui/core/styles/makeStyles';

export const PageBG = '#F4F6F8';

export const useGlobalStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: PageBG,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  pageTitle: {
    margin: theme.spacing(3),
    marginTop: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}))
