import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { IGroup } from '../../api/groups';
import DateView from '../../components/DateView';
import useRowSelect from '../../hooks/useRowSelect';
import { IBoard } from '../../api/boards';
import BoolChip from '../../components/UI/BoolChip';
import moment from 'moment';

interface IProps {
  boards: IBoard[];
  groups: IGroup[];
  onRowClick: any;
}

export default function BoardsList({ boards, groups, onRowClick }: IProps) {
  const [selectedIds, handleSelectOne, handleSelectAll] = useRowSelect(boards);

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.length === boards.length}
                    color="primary"
                    indeterminate={
                      selectedIds.length > 0 && selectedIds.length < boards.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Имя</TableCell>
                <TableCell>Адрес</TableCell>
                <TableCell>Группы</TableCell>
                <TableCell>Активно</TableCell>
                <TableCell>Изменено</TableCell>
                <TableCell>Последняя активность</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedIds.length > 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="right">С выбранными:</TableCell>
                  <TableCell>
                    <TextField select variant="outlined" label="Группа" size="small" style={{ width: 120 }}>
                      {groups.map((g) => <MenuItem key={g.id} value={g.id}>{g.id}</MenuItem>)}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField select variant="outlined" label="Активно" size="small" style={{ width: 120 }}>
                      <MenuItem value="1">Да</MenuItem>
                      <MenuItem value="0">Нет</MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined">Сохранить</Button>
                  </TableCell>
                </TableRow>
              )}
              {boards.map((b) => (
                <TableRow
                  hover
                  key={b.id}
                  selected={selectedIds.includes(b.id)}
                  onClick={() => onRowClick(b.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIds.includes(b.id)}
                      onChange={(event) => handleSelectOne(event, b.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>#{b.id}</TableCell>
                  <TableCell>{b.name}</TableCell>
                  <TableCell>{b.kiosk.address}</TableCell>
                  <TableCell>{b.groups?.filter((g) => !g.internal).map((g) => g.name).join(', ')}</TableCell>
                  <TableCell>
                    <BoolChip value={b.active} />
                  </TableCell>
                  <TableCell>
                    <DateView value={b.updatedAt} />
                  </TableCell>
                  <TableCell>
                    <span title={b.lastPing && moment(new Date(b.lastPing).getTime()).calendar()}>
                      {b.lastPing && moment(new Date(b.lastPing).getTime()).fromNow()}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};
