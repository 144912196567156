import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import GroupsList from './GroupsList';
import GroupsFilter, { defaultFilter } from './GroupsFilter';
import GroupDetails from './GroupDetails';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import { useGroupsList } from '../../api/groups';
import useFilter from '../../hooks/useFilter';

const emptyGroup = {
  name: '',
  description: ''
}

export default function GroupsPage() {
  const { id } = useParams()
  const classes = useGlobalStyles();
  const [setFilter, filter] = useFilter(defaultFilter);
  const [{ items }, loading, load] = useGroupsList();
  const [group, setGroup] = useState<any>()
  const navigate = useNavigate();

  useEffect(() => {
    load(filter);
  }, [filter])

  useEffect(() => {
    if (id) {
      if (id === 'new') {
        setGroup(emptyGroup);
      } else if (items.length) {
        setGroup(items.find((i: any) => i.id === parseInt(id)));
      }
    } else {
      setGroup(undefined);
    }
  }, [id, items])

  return (
    <Page
      className={classes.root}
      title="Группы панелей"
      action={<Button variant="contained" color="primary" onClick={() => navigate('/app/groups/new')}>Новая группа</Button>}
    >
      <GroupsFilter filter={filter} onApply={setFilter} />
      <Container maxWidth={false}>
        <Box mt={3}>
          <Loader loading={loading}>
            <GroupsList groups={items} onRowClick={(id: number) => navigate(`/app/groups/${id}`)} />
          </Loader>
        </Box>
      </Container>
      {group && (
        <GroupDetails
          group={group}
          onSave={() => load(filter)}
          onClose={() => navigate('/app/groups')}
        />
      )}
    </Page>
  );
};
