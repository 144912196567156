import React from 'react';

import { Line } from '@ant-design/charts';
import { Paper } from '@material-ui/core';


interface IProps {
    // data: object[],
    data: any,
}


const AdStatisticsChart = (props: IProps) => {
    const config = {
            data: props.data,
            xField: 'date',
            yField: 'conversionRate',
            seriesField: 'category',
            color: ['#1979C9', '#D62A0D'],
            legend: false,
            responsive: true
        };

    return (
        <Paper style={{ padding: 30 }}>
            { /* @ts-ignore */ }
            <Line {...config} />
        </Paper>
    );
};

export default AdStatisticsChart;
