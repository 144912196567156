import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import DateView from '../../components/DateView';
import useRowSelect from '../../hooks/useRowSelect';
import { IKiosk } from '../../api/kiosks';
import BoolChip from '../../components/UI/BoolChip';

interface IProps {
  kiosks: IKiosk[];
  onRowClick: any;
}

export default function KiosksList({ kiosks, onRowClick }: IProps) {
  const [selectedIds, handleSelectOne, handleSelectAll] = useRowSelect(kiosks);

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedIds.length === kiosks.length}
                    color="primary"
                    indeterminate={
                      selectedIds.length > 0
                      && selectedIds.length < kiosks.length
                    }
                    onChange={handleSelectAll}
                  />
                </TableCell>
                <TableCell>Имя</TableCell>
                <TableCell>Адрес</TableCell>
                <TableCell>
                  Активно
                </TableCell>
                <TableCell>Изменено</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedIds.length > 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="right">С выбранными:</TableCell>
                  <TableCell>
                    <TextField select variant="outlined" label="Группа" size="small" style={{ width: 120 }}>
                      <MenuItem value="1">Группа 1</MenuItem>
                      <MenuItem value="2">Группа 2</MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField select variant="outlined" label="Активно" size="small" style={{ width: 120 }}>
                      <MenuItem value="1">Да</MenuItem>
                      <MenuItem value="0">Нет</MenuItem>
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined">Сохранить</Button>
                  </TableCell>
                </TableRow>
              )}
              {kiosks.map((s: any) => (
                <TableRow
                  hover
                  key={s.id}
                  selected={selectedIds.includes(s.id)}
                  onClick={() => onRowClick(s.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedIds.includes(s.id)}
                      onChange={(event) => handleSelectOne(event, s.id)}
                      value="true"
                    />
                  </TableCell>
                  <TableCell>{s.name}</TableCell>
                  <TableCell>{s.address}</TableCell>
                  <TableCell>
                    <BoolChip value={s.active} />
                  </TableCell>
                  <TableCell>
                    <DateView value={s.updatedAt} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};
