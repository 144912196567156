import React, { useEffect, useRef, useState } from 'react';

import { useReactToPrint } from 'react-to-print';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import { ButtonGroup } from '@material-ui/core';

import { useGlobalStyles } from '../../theme/useGlobalStyles';

import { useKiosksList } from '../../api/kiosks';
import { useAdStatisticsList, useAdsList } from '../../api/ads';

import useFilter from '../../hooks/useFilter';
import Loader from '../../components/Loader';

import AdStatisticsFilter, { defaultFilter } from './AdStatisticsFilter';
import AdStatisticsChart from './AdStatisticsChart';
import AdStatisticsCards from './AdStatisticsCards';
import { AdStatisticsReport } from './AdStatisticsReport';
import dateFormat from '../../helpers/dates';
import { getCategoryFromId } from './utils';

const AdStatisticsPage = () => {
    const classes = useGlobalStyles();

    const componentRef = useRef<HTMLDivElement>(null);

    const [ setFilter, filter ] = useFilter(defaultFilter);

    const [ { items: kiosks }, loadingKiosks, loadKiosks ] = useKiosksList();
    const [ { items: ads }, loadingAds, loadAds ] = useAdsList();
    const [ { items: data }, loadingAdStatistics, loadAdStatistics ] = useAdStatisticsList();
    const [ { items: chartData }, loadingChartData, loadChartData ] = useAdStatisticsList();

    let totals = {
        person: 0,
        face: 0,
    };

    const mappedChartData = chartData.map((item: { conversionRate: number, hour?: string, day?: string, categoryId: number }) => {
        if (item.categoryId === 1) {
            totals.person += item.conversionRate
        } else {
            totals.face += item.conversionRate
        }

        return {
            conversionRate: item.conversionRate,
            date: item.hour ? dateFormat(item.hour, true) : dateFormat(item.day!),
            category: getCategoryFromId(item.categoryId)
        }
    })

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        loadKiosks({});
        loadAds();
        loadAdStatistics({ ...filter, kioskId: filter.kioskId || undefined, entityId: filter.entityId || undefined, groupByAdId: true });
        loadChartData({ ...filter, kioskId: filter.kioskId || undefined, entityId: filter.entityId || undefined })
    }, [ filter ]);

    const action = (
        <ButtonGroup color="primary">
            <Button variant="outlined" color="primary" onClick={handlePrint}>
                <PrintIcon/>
                <span style={{ marginLeft: 8 }}>Печать отчета</span>
            </Button>
        </ButtonGroup>
    );

    return (
        <Page className={classes.root} title="Статистика показов рекламы" action={action}>
            <AdStatisticsFilter filter={filter} kiosks={kiosks} ads={ads} onApply={setFilter}/>

            <Container maxWidth={false}>
                <Box mt={3}>
                    <Loader loading={loadingAdStatistics}>
                        <AdStatisticsCards
                            totalsPerson={totals.person.toFixed()}
                            totalsFace={totals.face.toFixed()}
                        />
                        <AdStatisticsChart data={mappedChartData} />
                        <div style={{ margin: '30px 0' }}/>
                        <AdStatisticsReport
                            // @ts-ignore
                            ref={componentRef}
                            data={data}
                            from={filter.periodFrom}
                            to={filter.periodTo}
                        />
                    </Loader>
                </Box>
            </Container>

        </Page>
    );
};


export default AdStatisticsPage;