import React from 'react';
import { IAdvertisersListRequest } from '../../api/advertisers';
import Filters from '../../components/Filter';
import { Input, BoolSelect } from '../../components/Form';

export const defaultFilter: IAdvertisersListRequest = {
  name: undefined,
  email: undefined,
  active: undefined
}

interface IProps {
  filter: IAdvertisersListRequest;
  onApply: any;
}

export default function AdvertisersFilter({ filter, onApply }: IProps) {
  return (
    <Filters initial={filter} onSubmit={onApply}>
      <Input name="name" label="Название" margin="dense" />
      <Input name="email" label="Email" margin="dense" />
      <BoolSelect name="email" label="Активен" margin="dense" />
    </Filters>
  )
}
