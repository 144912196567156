import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { Input } from '../../components/Form';
import { FormHelperText, Typography } from '@material-ui/core';

interface IProps {
  onSave: any;
  saving: boolean;
}

export default function AdvertiserTopUpBalance({ onSave, saving }: IProps) {
  return (
    <Form
      onSubmit={onSave}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <Typography variant="h6">Изменение баланса пользователя</Typography>
          <Input
            name="amount"
            label="Сумма"
            margin="normal"
          />
          <FormHelperText>Для уменьшения баланса введите отрицательное число, например -100</FormHelperText>

          <Box my={2}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={saving}
            >
              Изменить баланс
            </Button>
          </Box>
        </form>
      )}
    />
  )
}
