import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Page from '../../components/Page';
import RevenueFilter, { defaultFilter } from './RevenueFilter';

import { useGlobalStyles } from '../../theme/useGlobalStyles';
import useFilter from '../../hooks/useFilter';
import { useAdvertisersList } from '../../api/advertisers';
import { useAdRevenueReport } from '../../api/ads';
import { useGroupsList } from '../../api/groups';
import Loader from '../../components/Loader';
import RevenueAnalyticsChart from './RevenueChart';


export default function RevenuePage() {
    const classes = useGlobalStyles();

    const [ setFilter, filter ] = useFilter(defaultFilter);
    const [ { items: groups }, loadingGroups, loadGroups ] = useGroupsList();
    const [ { items: report }, loadingReport, loadReport ] = useAdRevenueReport();
    const [ { items: advertisers }, loadingAdvertisers, loadAdvertisers ] = useAdvertisersList();

    useEffect(() => {
        loadGroups();
        loadReport(filter);
        loadAdvertisers();
    }, [ filter ]);

    return (
        <Page className={classes.root} title="Доход">
            <RevenueFilter groups={groups} advertisers={advertisers} filter={filter} onApply={setFilter}/>
            <Container maxWidth={false}>
                <Box mt={3}>
                    <Loader loading={loadingReport}>
                        <RevenueAnalyticsChart data={report} mode={filter.advertisers?.length ? 'column' : 'line'}/>
                    </Loader>
                </Box>
            </Container>
        </Page>
    );
};
