import React from 'react';
import { Clear, Done } from '@material-ui/icons';
import { colors } from '@material-ui/core';

interface IProps {
  value: boolean;
  size?: 'inherit' | 'default' | 'small' | 'large';
}

export default function BoolChip({ value, size = 'large' }: IProps) {
  return <>
    {value ? (<Done htmlColor={colors.green[700]} fontSize={size}/>) : <Clear htmlColor={colors.red[600]} fontSize={size}/>}
  </>
}
