import React from 'react';
import moment from 'moment';

import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';

import { IBoard } from '../../api/boards';


interface IProps {
    board: IBoard;
}


export const BoardDeviceInfo = (props: IProps) => {

    const { timestamp, cpu, memory, ip } = props.board?.deviceInfo;

    if (typeof memory === 'undefined') {
        return <></>
    }

    const memoryPercent = Math.ceil(memory?.usedMemMb / memory?.totalMemMb * 100);

    return (
        <>
            <Typography variant="h6" gutterBottom component="div">
                Статистика устройства
            </Typography>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableBody>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Обновлено</TableCell>
                            <TableCell align="center">
                                {moment(new Date(timestamp).getTime()).calendar()}
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Нагрузка процессора (CPU)</TableCell>
                            <TableCell align="center">{Math.ceil(cpu)} %</TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">Память (занято)</TableCell>
                            <TableCell align="center">
                                {Math.ceil(memoryPercent)} %
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                            <TableCell component="th" scope="row">IP адрес</TableCell>
                            <TableCell align="center">{ip}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};