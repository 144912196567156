import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';
import { useGlobalStyles } from '../../theme/useGlobalStyles';

interface IProps {
  children: any;
  title?: string;
  className?: any;
  hidePageTitle?: boolean;
  action?: any;
}

const Page = forwardRef(({ children, title = '', hidePageTitle = false, action, className, ...rest }: IProps, ref: any) => {
  const classes = useGlobalStyles();
  return (
    <div
      ref={ref}
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Helmet>
        <title>{title}{title.length ? ' | ' : ''} City TV Admin panel</title>
      </Helmet>
      {!hidePageTitle && (
        <div className={classes.pageTitle}>
          <Typography variant="h3" color="primary">{title}</Typography>
          {action}
        </div>
      )}
      {children}
    </div>
  );
});

export default Page;
