import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Page from '../../components/Page';
import Loader from '../../components/Loader';
import NewsList from './NewsList';
import NewsFilter, { defaultFilter } from './NewsFilter';
import NewsDetails from './NewsDetails';
import { useGlobalStyles } from '../../theme/useGlobalStyles';
import { useNewsList, ISaveNewsRequest } from '../../api/news';
import { useGroupsList } from '../../api/groups';
import useFilter from '../../hooks/useFilter';
import { AdDuration } from '../../api/ads';

const emptyNews: ISaveNewsRequest = {
  name: '',
  title: '',
  groups: [],
  mainText: '',
  shortText: '',
  showDuration: AdDuration.DURATION_5S,
}

export default function NewsPage() {
  const { id } = useParams()
  const classes = useGlobalStyles();
  const [setFilter, filter] = useFilter(defaultFilter);
  const [{ items }, loading, load] = useNewsList();
  const [{ items: groups }, loadingGroups, loadGroups] = useGroupsList();
  const [details, setDetails] = useState<any>()
  const navigate = useNavigate();

  useEffect(() => {
    load(filter);
    loadGroups({});
  }, [filter])

  useEffect(() => {
    if (id) {
      if (id === 'new') {
        setDetails(emptyNews);
      } else if (items.length) {
        setDetails(items.find((i: any) => i.id === parseInt(id)));
      }
    } else {
      setDetails(undefined);
    }
  }, [id, items])

  return (
    <Page
      className={classes.root}
      title="Новости"
      action={<Button variant="contained" color="primary" onClick={() => navigate('/app/news/new')}>Добавить новость</Button>}
    >
      <NewsFilter filter={filter} onApply={setFilter} groups={groups} />
      <Container maxWidth={false}>
        <Box mt={3}>
          <Loader loading={loading || loadingGroups}>
            <NewsList
              news={items}
              onRowClick={(id: number) => navigate(`/app/news/${id}`)}
            />
          </Loader>
        </Box>
      </Container>
      {details && (
        <NewsDetails
          item={details}
          onSave={() => load(filter)}
          onClose={() => navigate('/app/news')}
          groups={groups}
        />
      )}
    </Page>
  );
};
