import React from 'react';

import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { ViewStatsCategory, getCategoryFromId } from './utils';


interface IProps {
    data: [],
    from: string,
    to: string,
}


export class AdStatisticsReport extends React.Component<IProps> {

    render() {
        const { data, from, to } = this.props;

        const getTextDate = (date: Date) => {
            return `${date.getDate()}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`;
        };

        const groupData = data.reduce<Record<string, {
            name: string,
            boards: string[],
            overall: Record<ViewStatsCategory, number>
        }>>((acc, item: any) => {
            const category = getCategoryFromId(item.categoryId)

            if (!acc[item.entityId]) {
                acc[item.entityId] = {
                    name: item.name,
                    boards: [],
                    overall: {
                        person: 0,
                        face: 0
                    }
                }
            }

            if (!acc[item.entityId].boards.includes(item.boardName)) {
                acc[item.entityId].boards.push(item.boardName)
            }

            acc[item.entityId].overall[category] += item.conversionRate

            return acc
        }, {})

        return (
            <Paper style={{ padding: 30, paddingTop: 15 }}>
                <Typography variant="overline" display="block" gutterBottom>
                    <span>Отчет по просмотрам рекламы от {getTextDate(new Date())}</span>
                    <span> (период: c {getTextDate(new Date(from))} по {getTextDate(new Date(to))})</span>
                </Typography>

                <TableContainer>
                    <Table size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Реклама</TableCell>
                                <TableCell>Показы на панелях</TableCell>
                                <TableCell width={200}>Люди</TableCell>
                                <TableCell width={200}>Просмотры</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(groupData).map((key: any) => (
                                <TableRow>
                                    <TableCell>{groupData[key].name}</TableCell>
                                    <TableCell>{groupData[key].boards.join(', ')}</TableCell>
                                    <TableCell>{Math.round((groupData[key].overall.person))}</TableCell>
                                    <TableCell>{Math.round((groupData[key].overall.face))}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        );
    }

}