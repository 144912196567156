import _ from 'lodash';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import arrayMutators from 'final-form-arrays';
import { IAdvertiser } from '../../../api/advertisers';
import Grid from '@material-ui/core/Grid';
import { IAdmin } from '../../../api/admins';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


interface IProps {
    admins: IAdmin[],
    advertiser: IAdvertiser;
    onSave: any;
    saving: boolean;
}

const AutocompleteWrapper = styled.div`
  width: calc(100% - 56px);
  margin-right: 6px;
  
  .MuiAutocomplete-clearIndicator {
    display: none;
  }
`;


export default function AdvertiserUsers({ advertiser, admins, onSave, saving }: IProps) {
    const [ advertisers = advertiser.admins, setAdvertisers ] = useState<Partial<IAdmin>[]>();

    const options = admins.map((a) => ({ title: a.email, value: a.id }));

    const removeAdmin = (i: number) => {
        advertisers.splice(i, 1);
        setAdvertisers([...advertisers]);
    }

    return (
        <Form onSubmit={() => {
            const admins: number[] = [];

            _.each(advertisers, a => a.id && admins.push(Number(a.id)));

            onSave({ id: advertiser.id, admins });

        }} mutators={{ ...arrayMutators }} initialValues={advertiser} render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <>
                    {advertisers?.map((advertiser, i) => (
                        <div key={i}>
                            <Grid item xs={12}>
                                <Box my={2} style={{ display: 'flex' }}>
                                    <AutocompleteWrapper>
                                        <Autocomplete id="combo-box-demo"
                                                      options={options}
                                                      onChange={(e, value) => {
                                                          advertisers[i] = _.find(admins, { id: value?.value }) as IAdmin;
                                                          value && setAdvertisers([...advertisers]);
                                                      }}
                                                      getOptionLabel={(option) => option.title}
                                                      value={_.find(options, { value: advertisers[i]?.id })}
                                                      renderInput={(params) => {
                                                          return (
                                                              <TextField {...params} label="Пользователь"
                                                                         variant="outlined"/>
                                                          )
                                                      }}/>
                                    </AutocompleteWrapper>
                                    <div>
                                        <IconButton aria-label="delete" onClick={() => removeAdmin(i)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </div>
                                </Box>
                            </Grid>

                            <Divider style={{ margin: '16px 0' }}/>
                        </div>
                    ))}
                    <Button variant="text" className="successButton" color="primary" onClick={() => {
                        setAdvertisers([ ...advertisers, {} ]);
                    }}>
                        Добавить пользователя
                    </Button>
                </>
                <Box my={2}>
                    <Button type="submit" variant="contained" color="primary" size="large" disabled={saving}>
                        Сохранить
                    </Button>
                </Box>
            </form>
        )}/>
    );
}
