import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';
import Logo from '../../components/Logo';
import { useMyInfo } from '../../api/selfSettings';

const useStyles = makeStyles((theme) => ({
  root: {},
  account: {
    width: 32,
    height: 32,
    borderRadius: 16,
    background: theme.palette.primary.dark,
    color: '#fff',
    display: 'block',
    textAlign: 'center',
    fontSize: 18,
    padding: '5px 0',
    marginRight: theme.spacing(2),
    transition: 'all 300ms ease-out 0s',
    textTransform: 'uppercase',
    fontWeight: 700,
    '&:hover': {
      background: theme.palette.primary.light
    }
  }
}))

interface IProps {
  className?: any;
  onMobileNavOpen: any;
}

export default function TopBar({ className, onMobileNavOpen, ...rest }: IProps) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [{ data: { email } }, loading, load] = useMyInfo();

  useEffect(() => {
    load();
  }, [])

  return (
    <AppBar
      className={className}
      elevation={0}
      {...rest}
    >
      <Toolbar>
        <RouterLink to="/app">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Hidden mdDown>
          {!loading && <RouterLink to="/app/account" className={classes.account} title="Настройки">{email?.charAt(0) || 'A'}</RouterLink>}
          <IconButton
            color="inherit"
            onClick={() => {
              Cookies.remove('auth:bo');
              navigate('/');
            }}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          {!loading && <RouterLink to="/app/account" className={classes.account}>{email?.charAt(0) || 'A'}</RouterLink>}
          <IconButton
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};
