import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import { Form } from 'react-final-form';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    '& > *': {
      marginRight: theme.spacing(2),
      width: '15%',
      '&:last-child': {
        marginRight: 0
      }
    }
  },
  button: {
    width: '120px !important',
    height: 40,
    marginTop: 3
  }
}))

interface IProps {
  children: any | any[];
  initial: any;
  onSubmit: any;
  btnText?: string;
}

export default function Filters({ children, initial, onSubmit, btnText = 'Поиск' }: IProps) {
  const classes = useStyles();

  return (
    <Container maxWidth={false}>
      <Form
        initialValues={initial}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} className={classes.root}>
            {children}
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              size="small"
              className={classes.button}
            >
              {btnText}
            </Button>
          </form>
        )}
      />
    </Container>
  )
}
