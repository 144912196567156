import axios from 'axios';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

const token = Cookies.get('auth:bo');
const headers: any = {};
if (token) {
  headers.Authorization = `Bearer ${token}`;
}

const Api = axios.create({
  headers,
  baseURL: (process.env.REACT_APP_API_HOST || window.location.hostname) + '/api',
});

export default Api;

interface IData {
  items?: any;
  full: boolean;
  data?: any;
  error?: any;
  left?: number;
}

export function useFetchApi(remote: any, initialData: any, fetchOnLoad = false): [IData, boolean, (...args: any) => void, (...args: any) => void] {
  const [data, setData] = useState({ ...initialData, error: null, full: false });
  const [loading, setLoading] = useState(fetchOnLoad);
  const { enqueueSnackbar } = useSnackbar();

  const load = async (...args: any[]) => {
    setLoading(true);
    const isPagination = args && args.length && args[0] && typeof(args[0]) === 'object' && !!args[0].cursor;
    if (!isPagination && data.items && data.items.length) {
      setData({ ...data, items: [] });
    }
    let res = initialData;
    try {
      res = await remote(...args);
    } catch (e: any) {
      let error = e.toString();
      if (e.response?.data?.error) {
        error = e.response.data.error.details
      }
      enqueueSnackbar(error, { variant: 'error' });
      if (e.response?.status === 401) {
        Cookies.remove('auth:bo');
        window.location.assign('/');
      } else {
        setData({ ...data, error });
      }
    }

    if (res.data) {
      if (res.data.error) {
        enqueueSnackbar((res.data.error.message || res.data.error.type), { variant: 'error' });
        setData({ ...data, error: res.data.error });
      } else {
        const respData = res.data.data;
        const isDataArr = Array.isArray(respData);
        const full = respData && isDataArr && respData.length < 50;
        setData({
          ...data,
          ...res.data,
          error: null,
          items: respData && isDataArr && data.items
            // @ts-ignore
            ? (isPagination ? data.items.concat(respData) : [].concat(isDataArr ? respData : [respData]))
            : [],
          data: !isDataArr ? respData : {},
          full
        });
      }
    }
    setLoading(false);
  };

  const updateItem = (item: any, compareKey = 'id', type = 'update') => {
    if (!item) return;
    const items = [...data.items];
    if (compareKey !== null) {
      const i = items.findIndex((it: any) => it[compareKey].toString() === item[compareKey].toString());
      if (i !== -1) {
        if (type === 'delete') {
          items.splice(i, 1);
        } else {
          items[i] = item;
        }
      } else {
        items.unshift(item);
      }
    }
    const upd = { ...data, items };
    if (type !== 'delete') {
      if (Array.isArray(upd.data)) {
        upd.data[0] = item;
      } else {
        upd.data = item;
      }
    } else {
      upd.data = {};
    }
    setData(upd);
  }

  return [data, loading, load, updateItem];
}

export function useUpdateApi(remote: any, callback: any, successMessage?: string): [(...args: any) => void, boolean] {
  const [updating, setUpdating] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const req = async (...args: any) => {
    setUpdating(true);

    try {
      const res = await remote(...args);
      if (res.data.error) {
        enqueueSnackbar(res.data.error, { variant: 'error' });
      }
      if (successMessage) {
        enqueueSnackbar(successMessage, { variant: 'success' });
      }
      callback(res.data);
    } catch (e: any) {
      let err = e.toString();
      if (e.response?.data?.error) {
        callback(e.response.data);
        err = e.response.data.error.details;
      }
      if (e.response?.status === 401) {
        Cookies.remove('auth:bo');
        window.location.assign('/');
      }
      if (err !== 'OTP required') {
        enqueueSnackbar(err, { variant: 'error' });
      }
    }

    setUpdating(false);
  };

  return [req, updating];
}

export interface IBaseModel {
  id: number;
  extId: number;
  createdAt: Date;
  updatedAt: Date;
}
