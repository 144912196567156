import React from 'react';
import { IAdminListRequest, UserRole, UserRoleName } from '../../api/admins';
import Filters from '../../components/Filter';
import { Input, BoolSelect, Select } from '../../components/Form';

export const defaultFilter: IAdminListRequest = {
  email: undefined,
  roles: undefined,
  active: undefined
}

interface IProps {
  filter: IAdminListRequest;
  onApply: any;
}

export default function AdminsFilter({ filter, onApply }: IProps) {
  return (
    <Filters initial={filter} onSubmit={onApply}>
      <Input name="email" label="Email" margin="dense" />
      <Select
        name="roles"
        label="Роли"
        options={Object.values(UserRole).map((r) => ({ value: r, label: UserRoleName[r] }))}
        margin="dense"
        multi
      />
      <BoolSelect name="active" label="Активен" margin="dense" />
    </Filters>
  )
}
