import React from 'react';
import { IGroup, IGroupsListRequest } from '../../api/groups';
import Filters from '../../components/Filter';
import { DateField, Select } from '../../components/Form';
import { IAdvertiser } from '../../api/advertisers';
import moment from 'moment';

export type IRevenueFilter = typeof defaultFilter

export const defaultFilter = {
    periodFrom: moment().subtract('1', 'month'),
    periodTo: moment(),
    groups: [],
    advertisers: [],
};

interface IProps {
    filter: IGroupsListRequest;
    onApply: any;
    groups: IGroup[];
    advertisers: IAdvertiser[];
}

export default function RevenueFilter({ filter, onApply, groups, advertisers }: IProps) {
    return (
        <Filters initial={filter} onSubmit={onApply}>
            <DateField name="periodFrom" label="Начало периода"/>
            <DateField name="periodTo" label="Конец периода"/>

            <Select name="groups"
                    label="Группы"
                    options={groups.map((g) => ({ value: g.id, label: g.name }))}
                    multi
                    margin="dense"/>
            <Select name="advertisers"
                    label="Рекламодатели"
                    options={advertisers.map((a) => ({ value: a.id, label: a.name }))}
                    multi
                    margin="dense"/>
        </Filters>
    );
}
