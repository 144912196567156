import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import BoolChip from '../../components/UI/BoolChip';
import DateView from '../../components/DateView';
import { INewsItem } from '../../api/news';

interface IProps {
  news: INewsItem[];
  onRowClick: any;
}

export default function NewsList({ news, onRowClick }: IProps) {

  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Заголовок</TableCell>
                <TableCell>
                  Активна
                </TableCell>
                <TableCell>Добавлена</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {news.map((n) => (
                <TableRow
                  hover
                  key={n.id}
                  onClick={() => onRowClick(n.id)}
                >
                  <TableCell>#{n.id}</TableCell>
                  <TableCell>{n.title}</TableCell>
                  <TableCell>
                    <BoolChip value={n.active} />
                  </TableCell>
                  <TableCell>
                    <DateView value={n.createdAt} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};
