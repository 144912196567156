import { useState } from 'react';

export default function useRowSelect(items: any[]): [any[], (e: any, id: any) => void, (e: any) => void] {
  const [selected, setSelected] = useState<any[]>([]);

  const handleSelectAll = (event: any) => {
    let newSelectedIds;

    if (event.target.checked) {
      newSelectedIds = items.map((i) => i.id);
    } else {
      newSelectedIds = [];
    }

    setSelected(newSelectedIds);
  };

  const handleSelectOne = (event: any, id: any) => {
    const selectedIndex = selected.indexOf(id);
    let newSelectedIds: string[] = [];

    if (selectedIndex === -1) {
      newSelectedIds = newSelectedIds.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelectedIds = newSelectedIds.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelectedIds = newSelectedIds.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedIds = newSelectedIds.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelectedIds);
  };

  return [selected, handleSelectOne, handleSelectAll];
}
